import PropTypes from 'prop-types';
import { trans } from '@spotahome/soyuz-trans/client';

import { useHeaderLinks } from '../../../context/ExternalLink';

import Button from '../../Button';
import GoogleIcon from '../../icons/GoogleIcon';
import FacebookRoundIcon from '../../icons/FacebookRoundIcon';

import styles from './LoginSignupModalSocial.module.scss';

const SOCIAL_NETWORKS = {
  google: {
    renderIcon: () => <GoogleIcon theme="dark" />,
    linkKey: 'googleLogin'
  },
  facebook: {
    renderIcon: () => <FacebookRoundIcon />,
    linkKey: 'facebookLogin'
  }
};

const LoginSignupModalSocialButton = ({ social, redirectUrl }) => {
  const socialConfig = SOCIAL_NETWORKS[social];
  const headerLinks = useHeaderLinks();

  const handleSocialButtonClick = () => {
    const newUrl = new URL(
      headerLinks[socialConfig.linkKey],
      document.location
    );

    if (redirectUrl) {
      newUrl.searchParams.set('redirectUrl', redirectUrl);
    }

    window.location.href = newUrl;
  };

  if (!socialConfig) {
    return null;
  }

  return (
    <Button
      onClick={handleSocialButtonClick}
      className={`${styles['login-signup-modal-social__button']} ga-signup-${social}`}
    >
      {socialConfig.renderIcon()}
      <span>{trans(`loginsignup.modal.${social}`)}</span>
    </Button>
  );
};

LoginSignupModalSocialButton.propTypes = {
  social: PropTypes.oneOf(Object.keys(SOCIAL_NETWORKS)).isRequired,
  redirectUrl: PropTypes.string.isRequired
};

const LoginSignupModalSocial = ({
  redirectUrl,
  hideGoogleOauth,
  hideFacebookOauth
}) => (
  <div className={styles['login-signup-modal-social']}>
    {hideGoogleOauth ? null : (
      <LoginSignupModalSocialButton social="google" redirectUrl={redirectUrl} />
    )}
    {hideFacebookOauth ? null : (
      <LoginSignupModalSocialButton
        social="facebook"
        redirectUrl={redirectUrl}
      />
    )}
  </div>
);

LoginSignupModalSocial.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  hideGoogleOauth: PropTypes.bool.isRequired,
  hideFacebookOauth: PropTypes.bool.isRequired
};

export default LoginSignupModalSocial;
