import { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { trans } from '@spotahome/soyuz-trans/client';
import ReCaptcha from 'react-google-recaptcha';

import { useSoyuzClientConfig, useSoyuzLocales } from '@spotahome/soyuz/client';

import FormProvider from '../../FormProvider';

import Success from './Success';

import styles from './ContactForm.module.scss';

const formSchema = yup.object().shape({
  email: yup
    .string()
    .ensure()
    .required('common.form.required-field')
    .email('lyp.validation.email.format'),
  name: yup.string().ensure().required('common.form.required-field'),
  comment: yup.string().ensure().required('common.form.required-field'),
  phoneNumber: yup.string().ensure().required('common.form.required-field')
});

let values = null;

const ContactForm = ({
  error = null,
  initialValues,
  onSubmit,
  onToggleAction
}) => {
  const [isFormSubmitted, setFormSubmitted] = useState(null);
  const { current: currentLocale } = useSoyuzLocales();
  const { recaptchaSiteKey } = useSoyuzClientConfig();
  const [recaptchaPassed, setRecaptchaPassed] = useState(false);
  const [loading, setLoading] = useState(false);

  const onCaptchaSuccess = () => {
    setRecaptchaPassed(true);
  };

  const onCaptchaExpired = () => {
    setRecaptchaPassed(false);
  };

  const handleFormSubmit = async payload => {
    setLoading(true);
    try {
      await onSubmit(payload);
      values = payload;
      setFormSubmitted(true);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return !isFormSubmitted ? (
    <FormProvider
      onSubmit={handleFormSubmit}
      schema={formSchema}
      initialValues={initialValues}
      fieldsClassName={styles['contact-form__field']}
      disabled={!recaptchaPassed}
      isLoading={loading}
    >
      <FormProvider.Input
        type="text"
        name="name"
        title={trans('contact-us.field.name.title')}
        placeholder={trans('contact-us.field.name.placeholder')}
        required
      />

      <FormProvider.Input
        type="email"
        name="email"
        title={trans('contact-us.field.email.title')}
        placeholder={trans('contact-us.field.email.placeholder')}
        required
      />

      <FormProvider.PhoneInput
        id="phoneNumber"
        name="phoneNumber"
        title={trans('contact-us.field.phoneNumber.title')}
        required
      />

      <FormProvider.Input
        type="textarea"
        name="comment"
        title={trans('contact-us.field.comment.title')}
        placeholder={trans('contact-us.field.comment.placeholder')}
        required
        rows={4}
      />

      <div className={styles['contact-form__recaptcha']}>
        <ReCaptcha
          hl={currentLocale}
          sitekey={recaptchaSiteKey}
          onExpired={onCaptchaExpired}
          onChange={onCaptchaSuccess}
        />
      </div>

      <div className={styles['contact-form__footer']}>
        <span className={styles['contact-form__submit']}>
          <FormProvider.Submit>
            {trans('loginsignup.modal.continue')}
          </FormProvider.Submit>
        </span>
        {isFormSubmitted && !!error && (
          <p
            data-test="contact-form-error"
            className={styles['contact-form__error']}
          >
            {error}
          </p>
        )}
      </div>
    </FormProvider>
  ) : (
    <Success {...values} onToggleAction={onToggleAction} />
  );
};

ContactForm.propTypes = {
  error: PropTypes.shape({
    handler: PropTypes.string,
    message: PropTypes.string
  }),
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onToggleAction: PropTypes.func.isRequired
};

export default ContactForm;
