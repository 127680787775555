import { useState } from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { trans } from '@spotahome/soyuz-trans/client';

import Button from '../../Button';

import FormProvider from '../../FormProvider';

import { FORGOTTEN_PASSWORD_SUCCESS, LOGIN } from '../constants';

import styles from './ForgottenPasswordForm.module.scss';

const formSchema = yup.object().shape({
  email: yup
    .string()
    .ensure()
    .required('common.form.required-field')
    .email('lyp.validation.email.format')
});

const ForgottenPasswordForm = ({
  onSubmit,
  onToggleAction,
  error = null,
  initialValues
}) => {
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  const handleToggleAction = () => {
    onToggleAction(LOGIN);
  };

  const handleSubmit = payload => {
    setFormSubmitted(true);
    onSubmit(payload);
    onToggleAction(FORGOTTEN_PASSWORD_SUCCESS);
  };

  return (
    <FormProvider
      onSubmit={handleSubmit}
      schema={formSchema}
      initialValues={initialValues}
      fieldsClassName={styles['forgotten-password-form__field']}
    >
      <FormProvider.Input
        type="email"
        name="email"
        title={trans('loginsignup.modal.email')}
        required
        autoFocus
        onBlurHandler={() => setFormSubmitted(false)}
      />

      <Button
        className={styles['forgotten-password-form__go-back']}
        onClick={handleToggleAction}
        color="link"
        dataTest="loginSignupModal_forgotPassword_goBack"
      >
        {trans('loginsignup.modal.signup-form.go-back')}
      </Button>

      <div className={styles['forgotten-password-form__footer']}>
        <p className={styles['forgotten-password-form__footer-tip']}>
          {trans('forgot-password.modal.claim')}
        </p>
        <span className={styles['forgotten-password-form__submit']}>
          <FormProvider.Submit>
            {trans('loginsignup.modal.continue')}
          </FormProvider.Submit>
        </span>
      </div>

      {isFormSubmitted && !!error && (
        <p
          data-test="forgotten-password-form-error"
          className={styles['forgotten-password-form__error']}
        >
          {error}
        </p>
      )}
    </FormProvider>
  );
};

ForgottenPasswordForm.propTypes = {
  onToggleAction: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  initialValues: PropTypes.shape({
    email: PropTypes.string
  }).isRequired
};

export default ForgottenPasswordForm;
