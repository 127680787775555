/* eslint-disable react/no-unused-prop-types */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Mediator } from '@spotahome/soyuz-mediator';
import { trans } from '@spotahome/soyuz-trans/client';
import classNames from 'classnames';

import Modal from '../Modal';
import ModalHeader from '../ModalHeader';
import Logo from '../../../base/Logo';

import { ExternalLinkProvider } from '../../context/ExternalLink';
import { isBiggerThanPhablet } from '../utils/breakpoints';
import debounce from '../utils/debounce';

import { COMMON_LOGIN_MODAL } from '../utils/constants';

import { updateOfferBoxInfo } from '../utils/temporalOfferToast';
import TemporalOfferToast from '../TemporalOfferToast/TemporalOfferToast';

import {
  LOGIN,
  SIGNUP,
  HELP,
  FORGOTTEN_PASSWORD,
  FORGOTTEN_PASSWORD_SUCCESS,
  MARKETING_OPT_IN,
  ACTIONS as AVAILABLE_ACTIONS,
  ACTIONS_WITH_SOCIAL
} from './constants';

import LoginSignupModalTitle from './components/LoginSignupModalTitle';
import LoginSignupModalSeparator from './components/LoginSignupModalSeparator';
import LoginSignupModalSocial from './components/LoginSignupModalSocial';

import LoginForm from './forms/LoginForm';
import SignupForm from './forms/SignupForm';
import ContactForm from './forms/ContactForm';
import ForgottenPasswordForm from './forms/ForgottenPasswordForm';
import ForgottenPasswordSuccessForm from './forms/ForgottenPasswordSuccessForm';

import styles from './LoginSignupModal.module.scss';

const defaultRenders = {
  renderTitle: action => trans(`${action}.modal.title`),
  renderSubtitle: action => trans(`${action}.modal.subtitle`)
};

const ACTIONS = {
  [LOGIN]: props => <LoginForm {...props} />,
  [SIGNUP]: props => <SignupForm {...props} />,
  [HELP]: props => <ContactForm {...props} />,
  [FORGOTTEN_PASSWORD]: props => <ForgottenPasswordForm {...props} />,
  [FORGOTTEN_PASSWORD_SUCCESS]: props => (
    <ForgottenPasswordSuccessForm {...props} />
  ),
  [MARKETING_OPT_IN]: () => <div>Placeholder for MARKETING_OPT_IN</div> // @TO-DO: implement marketing opt in form
};

const FORM_SUBMIT = {
  [LOGIN]: 'onTraditionalLogin',
  [SIGNUP]: 'onTraditionalSignup',
  [FORGOTTEN_PASSWORD]: 'onForgottenPassword',
  [HELP]: 'onHelpFormSubmit'
};

const INITIAL_SOCIAL_PAYLOAD = {
  redirectUrl: ''
};

const INITIAL_VALUES = {};

const LoginSignupModal = ({
  locale,
  error = null,
  enableTemporalOfferToast = false,
  shouldShowTemporalOfferToast = () => false,
  hideGoogleOauth = true,
  hideFacebookOauth = true,
  hideTraditionalAuth = true,
  ...restProps
}) => {
  const [isOpen, setOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(LOGIN);
  const [disableLoginSignUpToggle, setdisableLoginSignUpToggle] =
    useState(true);
  const [showMinimalHeader, setShowMinimalHeader] = useState(false);

  const customRenders = useRef(defaultRenders);
  const closeEvent = useRef();
  const socialOptions = useRef(INITIAL_SOCIAL_PAYLOAD);
  const initialValues = useRef(INITIAL_VALUES);

  const isTabletAndAbove = isBiggerThanPhablet();

  const openModal = (payload = {}) => {
    customRenders.current = {
      renderTitle: payload.renderTitle || defaultRenders.renderTitle,
      renderSubtitle: payload.renderSubtitle || defaultRenders.renderSubtitle
    };
    closeEvent.current = payload.events ? payload.events.close : null;
    socialOptions.current = payload.socialOptions || INITIAL_SOCIAL_PAYLOAD;
    initialValues.current = payload.initialValues || INITIAL_VALUES;

    setOpen(true);
    setCurrentAction(payload.initialAction || LOGIN);
    setdisableLoginSignUpToggle(
      payload.disableLoginSignUpToggle !== undefined
        ? payload.disableLoginSignUpToggle
        : false
    );
  };

  const handleClose = () => {
    setOpen(false);

    if (enableTemporalOfferToast && shouldShowTemporalOfferToast()) {
      updateOfferBoxInfo({ hasClosedSignupModal: true });
    }
  };

  useEffect(() => {
    Mediator.subscribe(COMMON_LOGIN_MODAL, openModal);

    return () => {
      Mediator.unsubscribe(COMMON_LOGIN_MODAL, openModal);
    };
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      setShowMinimalHeader(!isBiggerThanPhablet());
    }, 100);

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const mustShowSocial =
    ACTIONS_WITH_SOCIAL.includes(currentAction) &&
    (!hideGoogleOauth || !hideFacebookOauth);

  const handleToggleAction = newAction => {
    setCurrentAction(newAction);
  };

  const handleSubmit = (...payload) => {
    const formSubmit = FORM_SUBMIT[currentAction];
    restProps[formSubmit](...payload);
  };

  const renderHeaderLogo = () => (showMinimalHeader ? null : <Logo />);

  const authMethodClasses = classNames(
    styles['login-signup-modal__methods'],
    styles['login-signup-modal__methods--reversed']
  );

  return (
    <ExternalLinkProvider currentLocale={locale}>
      <Modal
        onRequestClose={handleClose}
        isOpen={isOpen}
        contentLabel="modal"
        size="fullscreen"
        className={styles['login-signup-modal']}
      >
        <div className={styles['login-signup-modal__image-container']} />
        <div
          data-test="login-signup-modal"
          className={styles['login-signup-modal__content-container']}
        >
          <ModalHeader
            showBack={showMinimalHeader}
            showClose={!showMinimalHeader}
            onRequestClose={handleClose}
            label={renderHeaderLogo()}
            className={styles['login-signup-modal__content-header']}
          />

          <div className={styles['login-signup-modal__content-body']}>
            <LoginSignupModalTitle
              renderTitle={() =>
                customRenders.current.renderTitle(currentAction)
              }
              renderSubtitle={() =>
                customRenders.current.renderSubtitle(currentAction)
              }
            />

            <div className={authMethodClasses}>
              {ACTIONS[currentAction] && !hideTraditionalAuth ? (
                <div
                  data-test={`loginSignupModal_${currentAction}`}
                  className={styles['login-signup-modal__action-container']}
                >
                  {ACTIONS[currentAction]({
                    onToggleAction: handleToggleAction,
                    onSubmit: handleSubmit,
                    onClose: handleClose,
                    isMobile: !isTabletAndAbove,
                    disableLoginSignUpToggle,
                    error,
                    initialValues: initialValues.current
                  })}
                </div>
              ) : null}
              {!hideTraditionalAuth && mustShowSocial ? (
                <LoginSignupModalSeparator />
              ) : null}
              {mustShowSocial && (
                <LoginSignupModalSocial
                  {...socialOptions.current}
                  hideGoogleOauth={hideGoogleOauth}
                  hideFacebookOauth={hideFacebookOauth}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
      {enableTemporalOfferToast && shouldShowTemporalOfferToast() && (
        <TemporalOfferToast isModalOpen={isOpen} />
      )}
    </ExternalLinkProvider>
  );
};

LoginSignupModal.LOGIN = LOGIN;
LoginSignupModal.SIGNUP = SIGNUP;
LoginSignupModal.FORGOTTEN_PASSWORD = FORGOTTEN_PASSWORD;
LoginSignupModal.AVAILABLE_ACTIONS = AVAILABLE_ACTIONS;

LoginSignupModal.propTypes = {
  locale: PropTypes.string.isRequired,
  onTraditionalLogin: PropTypes.func.isRequired,
  onTraditionalSignup: PropTypes.func.isRequired,
  onForgottenPassword: PropTypes.func.isRequired,
  error: PropTypes.string,
  enableTemporalOfferToast: PropTypes.bool,
  shouldShowTemporalOfferToast: PropTypes.func,
  hideGoogleOauth: PropTypes.bool,
  hideFacebookOauth: PropTypes.bool,
  hideTraditionalAuth: PropTypes.bool
};

export default LoginSignupModal;
