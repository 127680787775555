import { useState } from 'react';
import PropTypes from 'prop-types';

import * as yup from 'yup';

import { trans } from '@spotahome/soyuz-trans/client';

import FormProvider from '../../FormProvider';
import CheckboxWithLabel from '../../CheckboxWithLabel';
import Button from '../../Button';
import ModalHeader from '../../ModalHeader';

import LoginSignupModalTermsAndConditions from '../components/LoginSignupModalTermsAndConditions';
import LoginSignupModalToggle from '../components/LoginSignupModalToggle';

import { LOGIN, SIGNUP } from '../constants';

import styles from './SignupForm.module.scss';

const formSchema = yup.object().shape({
  email: yup
    .string()
    .ensure()
    .required('common.form.required-field')
    .email('lyp.validation.email.format')
});

const formSchemaStep2 = yup.object().shape({
  name: yup.string().ensure().required('common.form.required-field'),
  password: yup.string().ensure().required('common.form.required-field')
});

const SignupForm = ({
  onToggleAction,
  onSubmit,
  onClose,
  isMobile,
  error = null,
  disableLoginSignUpToggle = false,
  initialValues
}) => {
  const [stepNumber, setStepNumber] = useState(1);
  const [isOptInAccepted, setOptInAccepted] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  const handleToggleAction = () => {
    onToggleAction(LOGIN);
  };

  const handleOptInChange = value => {
    setOptInAccepted(value);
  };

  const handlePrevStep = () => {
    setFormSubmitted(false);
    setStepNumber(1);
  };

  const handleNextStep = payload => {
    setFormSubmitted(false);
    setFormValues(prevValues => ({
      ...prevValues,
      marketingOptIn: isOptInAccepted,
      ...payload
    }));
    setStepNumber(2);
  };

  const handleFormSubmit = payload => {
    setFormSubmitted(true);
    onSubmit({
      ...formValues,
      ...payload
    });
  };

  const handleSignupBackBtn = () => {
    if (stepNumber === 1) {
      onClose();
    }
    handlePrevStep();
  };

  return stepNumber === 1 ? (
    <FormProvider
      initialValues={formValues}
      onSubmit={handleNextStep}
      schema={formSchema}
      fieldsClassName={styles['signup-form__field']}
      key="fp-step-1"
    >
      {isMobile && (
        <ModalHeader
          showBack
          showClose={false}
          onRequestClose={handleSignupBackBtn}
          className={styles['signup-form__modal-header']}
        />
      )}

      <FormProvider.Input
        type="email"
        name="email"
        title={trans('loginsignup.modal.email')}
        required
        dataTest="loginSignupModalEmail"
      />

      <CheckboxWithLabel
        checked={isOptInAccepted}
        callback={handleOptInChange}
        text={trans('opt-in-checkbox-text')}
        name="marketingOptIn"
        id="marketingOptIn"
        className={styles['signup-form__opt-in-checkbox']}
      />

      <div className={styles['signup-form__footer']}>
        <span className={styles['signup-form__continue']}>
          <FormProvider.Submit
            fieldsClassName={styles['signup-form__submit']}
            dataTest="loginSignupModal_step1_cta"
          >
            {trans('loginsignup.modal.continue')}
          </FormProvider.Submit>
        </span>
        <span className={styles['signup-form__terms-and-conditions']}>
          <LoginSignupModalTermsAndConditions action={SIGNUP} />
        </span>
        {!disableLoginSignUpToggle && (
          <span className={styles['signup-form__toggle']}>
            <LoginSignupModalToggle
              action={SIGNUP}
              onToggleAction={handleToggleAction}
            />
          </span>
        )}
      </div>
    </FormProvider>
  ) : (
    <FormProvider
      initialValues={formValues}
      onSubmit={handleFormSubmit}
      schema={formSchemaStep2}
      fieldsClassName={styles['signup-form__field']}
      key="fp-step-2"
      onBlur={() => setFormSubmitted(false)}
    >
      {isMobile && (
        <ModalHeader
          showBack
          showClose={false}
          onRequestClose={handleSignupBackBtn}
          className={styles['signup-form__modal-header']}
        />
      )}
      <FormProvider.Input
        type="text"
        name="name"
        title={trans('loginsignup.modal.name')}
        required
        dataTest="loginSignupModalName"
        onBlurHandler={() => setFormSubmitted(false)}
      />

      <FormProvider.Password
        name="password"
        title={trans('loginsignup.modal.password')}
        required
        dataTest="loginSignupModalPassword"
        onBlurHandler={() => setFormSubmitted(false)}
      />

      <div className={styles['signup-form__footer']}>
        <LoginSignupModalTermsAndConditions action={SIGNUP} />
        <div className={styles['signup-form__actions']}>
          <Button
            className={styles['signup-form__go-back']}
            onClick={handlePrevStep}
            color="link"
            dataTest="loginSignupModal_step2_goBack"
          >
            {trans('loginsignup.modal.signup-form.go-back')}
          </Button>

          <FormProvider.Submit
            fieldsClassName={styles['signup-form__submit']}
            dataTest="loginSignupModal_step2_cta"
          >
            {trans('loginsignup.modal.signup-form.submit')}
          </FormProvider.Submit>
        </div>
      </div>

      {isFormSubmitted && !!error && (
        <p
          data-test="signup-form-error"
          className={styles['signup-form__error']}
        >
          {error}
        </p>
      )}
    </FormProvider>
  );
};

SignupForm.propTypes = {
  onToggleAction: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  error: PropTypes.string,
  disableLoginSignUpToggle: PropTypes.bool,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    name: PropTypes.string,
    password: PropTypes.string
  }).isRequired
};

export default SignupForm;
